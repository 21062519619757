import Cookies from 'js-cookie'
import React from 'react'

export type GoogleAnalyticsFieldMap = ReturnType<typeof getGoogleAnalyticsData>

export const getGoogleAnalyticsData = () => {
  const cookie = Cookies.get('__utmz')
  const clientIdCookie = Cookies.get('_ga')

  const clientId = clientIdCookie
    ? `${clientIdCookie.split('.').reverse()[1]}.${
        clientIdCookie.split('.').reverse()[0]
      }`
    : 'undefined'

  const data = {
    ga_clientid: clientId,
    ga_source: 'undefined',
    ga_campaign: 'undefined',
    ga_medium: 'undefined',
    ga_keyword: 'undefined',
    ga_content: 'undefined',
    ga_gclid: 'undefined',
  }

  if (cookie) {
    const parts = cookie.split('|')

    parts.forEach((part) => {
      if (part.includes('utmcsr=')) {
        data.ga_source = part.split('=')[1]
      } else if (part.includes('utmccn=')) {
        data.ga_campaign = part.split('=')[1]
      } else if (part.includes('utmcmd=')) {
        data.ga_medium = part.split('=')[1]
      } else if (part.includes('utmcct=')) {
        data.ga_content = part.split('=')[1]
      } else if (part.includes('utmctr=')) {
        data.ga_keyword = part.split('=')[1]
      } else if (part.includes('utmgclid=')) {
        data.ga_gclid = part.split('=')[1]
      }
    })
  }
  return data
}

// --------------------------------------------
// TODO: delete everything below
// --------------------------------------------
const GA_FIELD_NAMES = [
  'ga_source',
  'ga_campaign',
  'ga_keyword',
  'ga_medium',
  'ga_clientid',
  'ga_gclid',
  'ga_content',
] as const

const GoogleAnalyticsHiddenFields = () => (
  <>
    {GA_FIELD_NAMES.map((field) => (
      <input key={field} name={field} type="hidden" />
    ))}
  </>
)

export default React.memo(GoogleAnalyticsHiddenFields)

// --------------------------------------------
// TODO: delete everything up to here
// --------------------------------------------
