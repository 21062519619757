import { Link as GatsbyLink, navigate } from 'gatsby'
import React from 'react'

import { booleanFilter } from '../../utils/helpers'
import { useLocale } from '../LocaleContext'

interface LinkProps {
  link?: {
    external_url?: string | null
    links_to_?: string | null
    open_in_new_window_?: string | null
    text?: string | null
    title?: string
    anchor?: string | null
    internal_page?:
      | readonly ({
          id: string
          url?: string
          pageUrl?: string | null
        } | null)[]
      | null
    internal_overview_page?:
      | readonly ({
          id: string
          url?: string
          pageUrl?: string | null
        } | null)[]
      | null
    query_parameters?:
      | readonly ({
          id: string
          slug: string
          internal: {
            type: string
          }
        } | null)[]
      | null
  } | null
  directUrl?: string
  children: React.ReactChild
  state?: unknown
  onFocus?: React.FocusEventHandler<HTMLAnchorElement>
  onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseOver?: React.MouseEventHandler<HTMLAnchorElement>
  onMouseLeave?: React.MouseEventHandler<HTMLAnchorElement>
  onBlur?: React.FocusEventHandler<HTMLAnchorElement>
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export type TLink = LinkProps['link']

export const generateHeadingId = (stringValue: unknown) => {
  if (typeof stringValue !== 'string') {
    return undefined
  }

  return stringValue.toLowerCase().replace(/[^a-z0-9]/, '-')
}

const addAnchor = <T,>(link: T, anchor: unknown) => {
  if (anchor && typeof anchor === 'string' && typeof link === 'string') {
    return `${link}#${anchor.replace(/^#+/, '')}`
  }

  return link
}

const Link = (props: LinkProps) => {
  const {
    link,
    children,
    directUrl,
    state = {},
    onFocus,
    onMouseEnter,
    onMouseOver,
    onMouseLeave,
    onBlur,
    onClick,
  } = props
  const queryParams = link?.query_parameters?.filter(booleanFilter) || []
  const { siteLocale } = useLocale()

  if (link?.links_to_ === 'External URL' || directUrl?.startsWith('http')) {
    return (
      <a
        href={addAnchor(link?.external_url || directUrl || '/', link?.anchor)}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        rel="noopener noreferrer"
        target={link?.open_in_new_window_ === 'Yes' ? '_blank' : '_self'}
      >
        {children}
      </a>
    )
  }

  const linkTarget =
    link?.internal_page?.[0]?.url ||
    link?.internal_page?.[0]?.pageUrl ||
    link?.internal_overview_page?.[0]?.url ||
    link?.internal_overview_page?.[0]?.pageUrl ||
    directUrl

  if (queryParams.length > 0) {
    let targetUrl = `${linkTarget}/?`
    if (
      siteLocale.locale !== 'en-us' &&
      typeof siteLocale.locale !== 'undefined'
    ) {
      targetUrl = `/${siteLocale.locale}${targetUrl}`
    }
    queryParams.forEach((param, index) => {
      if (param.internal.type === 'Contentstack_topic_resource_topic') {
        targetUrl += `topic=${param.slug}`
      } else if (param.internal.type === 'Contentstack_topic_role') {
        targetUrl += `role=${param.slug}`
      } else if (param.internal.type === 'Contentstack_topic_industry') {
        targetUrl += `industry=${param.slug}`
      }
      if (index + 1 < queryParams.length) {
        targetUrl += '&'
      }
    })

    return (
      <a
        href={addAnchor(targetUrl, link?.anchor)}
        onBlur={onBlur}
        onClick={(event) => {
          onClick?.(event)
          navigate(targetUrl)
        }}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
      >
        {children}
      </a>
    )
  }

  let localizedTarget = linkTarget || '/'
  if (
    siteLocale.locale !== 'en-us' &&
    typeof siteLocale.locale !== 'undefined'
  ) {
    localizedTarget = `/${siteLocale.locale}${localizedTarget}`
  }

  // enforce trailing slash in url if it's not already present
  if (localizedTarget.slice(-1) !== '/') {
    localizedTarget += '/'
  }

  return (
    <GatsbyLink
      onBlur={onBlur}
      onFocus={onFocus}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
      state={state}
      to={addAnchor(localizedTarget, link?.anchor)}
    >
      {children}
    </GatsbyLink>
  )
}

export default Link
