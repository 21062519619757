import { FormikProps } from 'formik'

export const fieldError = <Values, T extends FormikProps<Values>>(
  { touched, errors }: T,
  field: keyof T['values'],
) => Boolean(touched[field] && errors[field])

export const fieldErrorText = <Values, T extends FormikProps<Values>>(
  { touched, errors }: T,
  field: keyof T['values'],
) => (touched[field] ? errors[field] : null)
