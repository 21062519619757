// @ts-nocheck
import { ClearbitResponse } from '../../hooks/useClearbitUserData'

export type RequiredFormInputs = {
  firstName: string
  lastName: string
  company: string
  country: string
  jobTitle: string
}
export const blankValues: RequiredFormInputs = {
  firstName: '',
  lastName: '',
  company: '',
  country: '',
  jobTitle: '',
}

export const EMPTY: ClearbitResponse = {
  person: null,
  company: null
}
export const NAVIN: ClearbitResponse = {
  "person": {
    "id": "8c7a3407-0448-4a8b-82ba-83b6b360903b",
    "name": {
      "fullName": "Navin Ruthuvarnan",
      "givenName": "Navin",
      "familyName": "Ruthuvarnan"
    },
    "email": "navin.ruthuvarnan@se.com",
    "location": "Toronto, ON, CA",
    "timeZone": "America/Toronto",
    "utcOffset": -4,
    "geo": {
      "city": "Toronto",
      "state": "Ontario",
      "stateCode": "ON",
      "country": "Canada",
      "countryCode": "CA",
      "lat": 43.653226,
      "lng": -79.3831843
    },
    "bio": null,
    "site": null,
    "avatar": null,
    "employment": {
      "domain": "se.com",
      "name": "Schneider Electric",
      "title": "Learning, Business Impact Manager",
      "role": "sales",
      "subRole": "business_development",
      "seniority": "manager"
    },
    "facebook": {
      "handle": null
    },
    "github": {
      "handle": null,
      "id": null,
      "avatar": null,
      "company": null,
      "blog": null,
      "followers": null,
      "following": null
    },
    "twitter": {
      "handle": null,
      "id": null,
      "bio": null,
      "followers": null,
      "following": null,
      "statuses": null,
      "favorites": null,
      "location": null,
      "site": null,
      "avatar": null
    },
    "linkedin": {
      "handle": "in/navin-ruthuvarnan-92936b4"
    },
    "googleplus": {
      "handle": null
    },
    "gravatar": {
      "handle": null,
      "urls": [],
      "avatar": null,
      "avatars": []
    },
    "fuzzy": false,
    "emailProvider": false,
    "indexedAt": "2024-10-22T13:32:45.228Z",
    "inactiveAt": null
  },
  "company": {
    "id": "f945044a-6b3d-450b-9081-18aa072e5a48",
    "name": "Schneider Electric",
    "legalName": null,
    "domain": "se.com",
    "domainAliases": [
      "surgeassure.com",
      "schneideruniversities.com",
      "buyautomation.com",
      "eschneider.pl",
      "schneider-infra.in"
    ],
    "site": {
      "phoneNumbers": [
        "+1 800-789-3508"
      ],
      "emailAddresses": []
    },
    "category": {
      "sector": "Utilities",
      "industryGroup": "Utilities",
      "industry": "Utilities",
      "subIndustry": "Utilities",
      "gicsCode": "55105010",
      "sicCode": "49",
      "sic4Codes": [
        "4939"
      ],
      "naicsCode": "22",
      "naics6Codes": [
        "221122"
      ],
      "naics6Codes2022": [
        "221122"
      ]
    },
    "tags": [
      "Automation",
      "Electric Power",
      "Power Distribution",
      "Utilities",
      "Industrials & Manufacturing"
    ],
    "description": "Schneider Electric is a global leader in energy management and automation, providing integrated solutions for sustainability and efficiency in homes, buildings, data centers, and industries worldwide.",
    "foundedYear": 1836,
    "location": "35 Rue Joseph Monier, 92500 Rueil-Malmaison, France",
    "timeZone": "Europe/Paris",
    "utcOffset": 2,
    "geo": {
      "streetNumber": "35",
      "streetName": "Rue Joseph Monier",
      "subPremise": null,
      "streetAddress": "35 Rue Joseph Monier",
      "city": "Rueil-Malmaison",
      "postalCode": "92500",
      "state": "Île-de-France",
      "stateCode": "IDF",
      "country": "France",
      "countryCode": "FR",
      "lat": 48.890891,
      "lng": 2.172649
    },
    "logo": "https://logo.clearbit.com/se.com",
    "facebook": {
      "handle": null,
      "likes": null
    },
    "linkedin": {
      "handle": "company/schneider-electric"
    },
    "twitter": {
      "handle": "SchneiderElec",
      "id": "393757140",
      "bio": "We are your digital partner for sustainability and efficiency, ensuring #LifeIsOn, for homes, buildings, data centers, infrastructure and industries.",
      "followers": 68407,
      "following": 2879,
      "location": "",
      "site": "https://t.co/vma4PKRDZ1",
      "avatar": "https://pbs.twimg.com/profile_images/1484187800253595649/JUxPBZli_normal.jpg"
    },
    "crunchbase": {
      "handle": null
    },
    "emailProvider": false,
    "type": "private",
    "ticker": null,
    "identifiers": {
      "usEIN": null,
      "usCIK": null
    },
    "phone": "+1 800-789-3508",
    "metrics": {
      "alexaUsRank": 5857,
      "alexaGlobalRank": 5498,
      "trafficRank": "very_high",
      "employees": 166025,
      "employeesRange": "100K+",
      "marketCap": null,
      "raised": 1600000,
      "annualRevenue": 38856260000,
      "estimatedAnnualRevenue": "$10B+",
      "fiscalYearEnd": null
    },
    "indexedAt": "2024-10-12T01:45:38.696Z",
    "tech": [
      "apache",
      "google_tag_manager",
      "microsoft_exchange_online",
      "outlook",
      "recaptcha",
      "microsoft_office_365",
      "youtube",
      "twitter_button",
      "typekit_by_adobe"
    ],
    "techCategories": [
      "web_servers",
      "tag_management",
      "email_hosting_service",
      "authentication_services",
      "productivity",
      "image_video_services",
      "social_sharing",
      "website_optimization"
    ],
    "parent": {
      "domain": null
    },
    "ultimateParent": {
      "domain": null
    }
  }
}
export const NOBODY: ClearbitResponse = {
  "person": null,
  "company": {
    "id": "f945044a-6b3d-450b-9081-18aa072e5a48",
    "name": "Schneider Electric",
    "legalName": null,
    "domain": "se.com",
    "domainAliases": [
      "surgeassure.com",
      "schneideruniversities.com",
      "buyautomation.com",
      "eschneider.pl",
      "schneider-infra.in"
    ],
    "site": {
      "phoneNumbers": [
        "+1 800-789-3508"
      ],
      "emailAddresses": []
    },
    "category": {
      "sector": "Utilities",
      "industryGroup": "Utilities",
      "industry": "Utilities",
      "subIndustry": "Utilities",
      "gicsCode": "55105010",
      "sicCode": "49",
      "sic4Codes": [
        "4939"
      ],
      "naicsCode": "22",
      "naics6Codes": [
        "221122"
      ],
      "naics6Codes2022": [
        "221122"
      ]
    },
    "tags": [
      "Automation",
      "Electric Power",
      "Power Distribution",
      "Utilities",
      "Industrials & Manufacturing"
    ],
    "description": "Schneider Electric is a global leader in energy management and automation, providing integrated solutions for sustainability and efficiency in homes, buildings, data centers, and industries worldwide.",
    "foundedYear": 1836,
    "location": "35 Rue Joseph Monier, 92500 Rueil-Malmaison, France",
    "timeZone": "Europe/Paris",
    "utcOffset": 2,
    "geo": {
      "streetNumber": "35",
      "streetName": "Rue Joseph Monier",
      "subPremise": null,
      "streetAddress": "35 Rue Joseph Monier",
      "city": "Rueil-Malmaison",
      "postalCode": "92500",
      "state": "Île-de-France",
      "stateCode": "IDF",
      "country": "France",
      "countryCode": "FR",
      "lat": 48.890891,
      "lng": 2.172649
    },
    "logo": "https://logo.clearbit.com/se.com",
    "facebook": {
      "handle": null,
      "likes": null
    },
    "linkedin": {
      "handle": "company/schneider-electric"
    },
    "twitter": {
      "handle": "SchneiderElec",
      "id": "393757140",
      "bio": "We are your digital partner for sustainability and efficiency, ensuring #LifeIsOn, for homes, buildings, data centers, infrastructure and industries.",
      "followers": 68407,
      "following": 2879,
      "location": "",
      "site": "https://t.co/vma4PKRDZ1",
      "avatar": "https://pbs.twimg.com/profile_images/1484187800253595649/JUxPBZli_normal.jpg"
    },
    "crunchbase": {
      "handle": null
    },
    "emailProvider": false,
    "type": "private",
    "ticker": null,
    "identifiers": {
      "usEIN": null,
      "usCIK": null
    },
    "phone": "+1 800-789-3508",
    "metrics": {
      "alexaUsRank": 5857,
      "alexaGlobalRank": 5498,
      "trafficRank": "very_high",
      "employees": 166025,
      "employeesRange": "100K+",
      "marketCap": null,
      "raised": 1600000,
      "annualRevenue": 38856260000,
      "estimatedAnnualRevenue": "$10B+",
      "fiscalYearEnd": null
    },
    "indexedAt": "2024-10-12T01:45:38.696Z",
    "tech": [
      "apache",
      "google_tag_manager",
      "microsoft_exchange_online",
      "outlook",
      "recaptcha",
      "microsoft_office_365",
      "youtube",
      "twitter_button",
      "typekit_by_adobe"
    ],
    "techCategories": [
      "web_servers",
      "tag_management",
      "email_hosting_service",
      "authentication_services",
      "productivity",
      "image_video_services",
      "social_sharing",
      "website_optimization"
    ],
    "parent": {
      "domain": null
    },
    "ultimateParent": {
      "domain": null
    }
  }
}
export const BILL: ClearbitResponse = {
  "person": {
    "id": "11f152d4-f226-46ee-bcf1-fec76a3d7a6d",
    "name": {
      "fullName": "Bill Gates",
      "givenName": "Bill",
      "familyName": "Gates"
    },
    "email": "bill.gates@gmail.com",
    "location": "Vancouver, WA, US",
    "timeZone": "America/Los_Angeles",
    "utcOffset": -7,
    "geo": {
      "city": "Vancouver",
      "state": "Washington",
      "stateCode": "WA",
      "country": "United States",
      "countryCode": "US",
      "lat": 45.6355146,
      "lng": -122.55783
    },
    "bio": null,
    "site": null,
    "avatar": null,
    "employment": {
      "domain": null,
      "name": null,
      "title": null,
      "role": null,
      "subRole": null,
      "seniority": null
    },
    "facebook": {
      "handle": null
    },
    "github": {
      "handle": null,
      "id": null,
      "avatar": null,
      "company": null,
      "blog": null,
      "followers": null,
      "following": null
    },
    "twitter": {
      "handle": null,
      "id": null,
      "bio": null,
      "followers": null,
      "following": null,
      "statuses": null,
      "favorites": null,
      "location": null,
      "site": null,
      "avatar": null
    },
    "linkedin": {
      "handle": null
    },
    "googleplus": {
      "handle": null
    },
    "gravatar": {
      "handle": null,
      "urls": [],
      "avatar": null,
      "avatars": []
    },
    "fuzzy": false,
    "emailProvider": true,
    "indexedAt": "2024-10-09T20:01:57.734Z",
    "inactiveAt": null
  },
  "company": null
}

export const BILL_GATES: ClearbitResponse = {
  "person": {
    "id": "b96a0ecd-5d87-47dd-9e60-0694d4663f1a",
    "name": {
      "fullName": "Bill Gates",
      "givenName": "Bill",
      "familyName": "Gates"
    },
    "email": "bill.gates@microsoft.com",
    "location": "Seattle, WA, US",
    "timeZone": "America/Los_Angeles",
    "utcOffset": -7,
    "geo": {
      "city": "Seattle",
      "state": "Washington",
      "stateCode": "WA",
      "country": "United States",
      "countryCode": "US",
      "lat": 47.6062095,
      "lng": -122.3320708
    },
    "bio": null,
    "site": null,
    "avatar": null,
    "employment": {
      "domain": "microsoft.com",
      "name": "Microsoft",
      "title": "Co-founder",
      "role": "leadership",
      "subRole": "founder",
      "seniority": "executive"
    },
    "facebook": {
      "handle": null
    },
    "github": {
      "handle": null,
      "id": null,
      "avatar": null,
      "company": null,
      "blog": null,
      "followers": null,
      "following": null
    },
    "twitter": {
      "handle": null,
      "id": null,
      "bio": null,
      "followers": null,
      "following": null,
      "statuses": null,
      "favorites": null,
      "location": null,
      "site": null,
      "avatar": null
    },
    "linkedin": {
      "handle": "in/williamhgates"
    },
    "googleplus": {
      "handle": null
    },
    "gravatar": {
      "handle": "garten530",
      "urls": [],
      "avatar": null,
      "avatars": []
    },
    "fuzzy": false,
    "emailProvider": false,
    "indexedAt": "2024-09-28T00:37:29.809Z",
    "inactiveAt": "2016-12-03"
  },
  "company": {
    "id": "6227df49-e4a8-4223-8f5e-f7e12289f4b3",
    "name": "Microsoft",
    "legalName": "Microsoft Corporation",
    "domain": "microsoft.com",
    "domainAliases": [
      "microsoft.com.tw",
      "outlook.sa",
      "update.microsoft",
      "lift.london",
      "microsoft.cg",
      "webmatrix.com",
      "microsoft.condos",
      "microsoft.co.za",
      "microsofthup.com",
      "microsoftband.com",
      "www.microsoft",
      "microsoftjobsblog.com",
      "microsofttech.com.br",
      "microsoft.li",
      "microsoft.hockey",
      "insideouthair.com",
      "microsoftaccelerator.com",
      "live.it",
      "microsoft.security",
      "exrca.com",
      "microsoft.co.jp",
      "outllook.com",
      "hotmail.co.jp",
      "microsoft.sucks",
      "o365.com",
      "surface.com",
      "onedrive.com",
      "microsoft.xyz",
      "1drv.com",
      "yahoobingnetwork.com",
      "microsoft.cloud",
      "zoom.co",
      "microcsoft.com",
      "micrsoft.com",
      "microsoft.direct",
      "microsoft.hamburg",
      "office365.com",
      "microsoft.com.tr",
      "microsoft.cd",
      "microsoft.eu",
      "microsoft.cl",
      "microsot.com",
      "xandr.com",
      "outlook.com.ar",
      "outlook.be",
      "microsoft.bi",
      "msopentech.com",
      "onedrive.us",
      "microsoft.hosting",
      "onedrive.ru",
      "hotmmail.com",
      "microsoft.com.lc",
      "microsft.com",
      "microsoft.com.ar",
      "microsoft.ee",
      "microsoft.cars",
      "microsoft.org.nz",
      "microsoft.world",
      "hotmail.de",
      "www.hotmail",
      "microsoft.uk",
      "iegallery.com",
      "microsoft.vip",
      "signalr.net",
      "microsoftadvertising.com",
      "rolandguijt.com",
      "microsoft.kaufen",
      "riskiq.com",
      "blogmicrosofteducacao.com.br",
      "hotmaim.com",
      "microsoft.com.co",
      "microsoft.webcam",
      "dot.net",
      "layerscape.org",
      "microsoft.irish",
      "mirosoft.com",
      "doc.io",
      "microsoft.co.id",
      "digitaler-bildungspakt.de",
      "gotoally.com",
      "microsoft.net.cn",
      "microsoft.business",
      "microsoft.tt",
      "microsoft.storage",
      "fabrikam.com",
      "visualstudio.net",
      "microsoft.co.tt",
      "microsoft.gives",
      "microsoft.ski",
      "fieldone.com",
      "futuredecoded.com",
      "microsoft.com.sa",
      "office.cm",
      "hotmail.com.sg",
      "microsoft.com.hk",
      "tealsk12.org",
      "appsource.com",
      "microsoft.com.br",
      "bingtoolbar.com",
      "contoso.com"
    ],
    "site": {
      "phoneNumbers": [],
      "emailAddresses": []
    },
    "category": {
      "sector": "Information Technology",
      "industryGroup": "Software & Services",
      "industry": "Internet Software & Services",
      "subIndustry": "Internet Software & Services",
      "gicsCode": "45103010",
      "sicCode": "73",
      "sic4Codes": [
        "7372"
      ],
      "naicsCode": "51",
      "naics6Codes": [
        "511210"
      ],
      "naics6Codes2022": [
        "513210"
      ]
    },
    "tags": [
      "Information Technology & Services",
      "Software",
      "Publishers",
      "Information",
      "Publishing",
      "Technology",
      "SAAS",
      "B2B",
      "Enterprise"
    ],
    "description": "Microsoft develops, licenses, and supports a wide range of software products and services, including operating systems, productivity applications, and cloud-based solutions, while also designing and manufacturing various devices such as personal comput...",
    "foundedYear": 1975,
    "location": "1 Microsoft Way, Redmond, WA 98052, USA",
    "timeZone": "America/Los_Angeles",
    "utcOffset": -7,
    "geo": {
      "streetNumber": "1",
      "streetName": "Microsoft Way",
      "subPremise": null,
      "streetAddress": "1 Microsoft Way",
      "city": "Redmond",
      "postalCode": "98052",
      "state": "Washington",
      "stateCode": "WA",
      "country": "United States",
      "countryCode": "US",
      "lat": 47.6393782,
      "lng": -122.1282593
    },
    "logo": "https://logo.clearbit.com/microsoft.com",
    "facebook": {
      "handle": "microsoft",
      "likes": 11796560
    },
    "linkedin": {
      "handle": "company/microsoft"
    },
    "twitter": {
      "handle": "Microsoft",
      "id": "74286565",
      "bio": "We're on a mission to empower every person and every organization on the planet to achieve more. Support: @MicrosoftHelps",
      "followers": 12814907,
      "following": 2462,
      "location": "Redmond, WA",
      "site": "https://t.co/pVVgYRVYim",
      "avatar": "https://pbs.twimg.com/profile_images/1639311258297024513/Oi270Aq-_normal.jpg"
    },
    "crunchbase": {
      "handle": "organization/microsoft"
    },
    "emailProvider": false,
    "type": "public",
    "ticker": "MSFT",
    "identifiers": {
      "usEIN": "911144442",
      "usCIK": "0000789019"
    },
    "phone": "+1 425-882-8080",
    "metrics": {
      "alexaUsRank": 19,
      "alexaGlobalRank": 19,
      "trafficRank": "very_high",
      "employees": 221000,
      "employeesRange": "100K+",
      "marketCap": 640670000000,
      "raised": null,
      "annualRevenue": 211900000000,
      "estimatedAnnualRevenue": "$10B+",
      "fiscalYearEnd": 6
    },
    "indexedAt": "2024-09-30T06:25:44.960Z",
    "tech": [
      "microsoft_exchange_online",
      "microsoft_office_365",
      "adobe_dynamic_tag_management",
      "bing_advertiser",
      "clicktale",
      "google_tag_manager"
    ],
    "techCategories": [
      "email_hosting_service",
      "productivity",
      "tag_management",
      "advertising",
      "analytics"
    ],
    "parent": {
      "domain": null
    },
    "ultimateParent": {
      "domain": null
    }
  }
}
