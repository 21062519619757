import { useEffect, useState } from 'react'

import { TAssetImgix } from './types'

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number,
) => {
  let timeout: any

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout)
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor)
    })
}

export const isBrowser = (): boolean => typeof window !== 'undefined'

export const cleanString = (string?: string | null) =>
  string ? string.replace(/<[^>]+>/g, '') : ''

export const sanitizeUrlAnchor = (string: string | null | undefined) =>
  encodeURIComponent(
    string
      ?.toLocaleLowerCase()
      ?.replace(/<[^>]+>/g, '')
      ?.replace(/[^a-z0-9]+/g, '-') ?? '',
  )

export const booleanFilter = <T>(
  value: T | boolean | null | undefined | 0 | '',
): value is T => Boolean(value)

export const jsonParseLocalStorage = <Result>(string: string | null) => {
  if (!string) {
    return null
  }

  try {
    return JSON.parse(string) as Result
  } catch (error) {
    // do nothing
  }

  return null
}

export const useServerSideSafeValue = <T>(
  getter: () => T,
): { value: T | null; isReady: boolean } => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setIsReady(true)
  }, [])

  if (!isReady) {
    return { value: null, isReady: false }
  }

  return { value: getter(), isReady: true }
}

export const buildImageDataLike = (id: string, imgixImage?: TAssetImgix) => ({
  id,
  children: [],
  parent: null,
  internal: { contentDigest: '', owner: '', type: '' },
  ...(imgixImage ?? {}),
})
