import { useQuery } from '@tanstack/react-query'
import { validate } from 'email-validator'

export type ClearbitUserDataType = {
  clearbitCompany: string | undefined
  clearbitCompanySize: number | undefined
  clearbitEmployeesRange: string | undefined
  clearbitPhoneNumber: string | undefined
  clearbitCountry: string | undefined
  clearbitSicCode: string | undefined
  clearbitAnnualRevenue: number | undefined
  clearbitEmailProvider: boolean | undefined
  clearbitUltimateParentDomain: string | undefined
  clearbitLinkedinHandle: string | undefined
  clearbitFuzzy: boolean | undefined
  clearbitIndexedAt: string | undefined
}

export type ClearbitResponse = {
  person: null | {
    id: string
    name: {
      fullName: string
      givenName: string
      familyName: string
    }
    email: string
    location: string
    timeZone: string
    utcOffset: number
    geo: {
      city: string
      state: string
      stateCode: string
      country: string
      countryCode: string
      lat: number
      lng: number
    }
    bio: string | null
    site: string | null
    avatar: string | null
    employment: {
      domain: string
      name: string
      title: string
      role: string
      subRole: string
      seniority: string
    }
    facebook: {
      handle: null
    }
    github: {
      handle: string | null
      id: string | null
      avatar: string | null
      company: string | null
      blog: string | null
      followers: number | null
      following: number | null
    }
    twitter: {
      handle: string | null
      id: string | null
      bio: string | null
      followers: number | null
      following: number | null
      statuses: null
      favorites: null
      location: null
      site: string | null
      avatar: string | null
    }
    linkedin: {
      handle: string
    }
    googleplus: {
      handle: null
    }
    gravatar: {
      handle: string
      urls: string[]
      avatar: string | null
      avatars: string[]
    }
    fuzzy: boolean
    emailProvider: boolean
    indexedAt: string
    inactiveAt: string | null
  }
  company: null | {
    id: string | null
    name: string | null
    legalName: string | null
    domain: string | null
    domainAliases: string[]
    site: {
      phoneNumbers: string[]
      emailAddresses: string[]
    }
    category: {
      sector: string | null
      industryGroup: string | null
      industry: string | null
      subIndustry: string | null
      sicCode: string | null
      naicsCode: string | null
    }
    tags: string[]
    description: string | null
    foundedYear: number | null
    location: string
    timeZone: string
    utcOffset: number
    geo: {
      streetNumber: string | null
      streetName: string | null
      subPremise: string | null
      streetAddress: string | null
      city: string | null
      postalCode: string | null
      state: string | null
      stateCode: string | null
      country: string | null
      countryCode: string | null
      lat: number | null
      lng: number | null
    }
    logo: string | null
    facebook: {
      handle: string | null
      likes: number | null
    }
    linkedin: {
      handle: string | null
    }
    twitter: {
      handle: string | null
      id: string | null
      bio: string | null
      followers: number | null
      following: number | null
      location: string | null
      site: string | null
      avatar: string | null
    }
    crunchbase: {
      handle: string | null
    }
    emailProvider: boolean | null
    type: string | null
    ticker: string | null
    identifiers: {
      usEIN: string | null
    }
    phone: string | null
    metrics: {
      alexaUsRank: number | null
      alexaGlobalRank: number | null
      employees: number | null
      employeesRange: string | null
      marketCap: number | null
      raised: number | null
      annualRevenue: number | null
      estimatedAnnualRevenue: string | null
      fiscalYearEnd: number | null
    }
    indexedAt: string
    tech: string[]
    techCategories: string[]
    parent: {
      domain: string | null
    }
    ultimateParent: {
      domain: string | null
    }
  }
}

const fetchData = async (email: string): Promise<ClearbitResponse> => {
  try {
    const response = await fetch(
      `https://person.clearbit.com/v1/combined/email/${email}`,
      {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_CLEARBIT_API_TOKEN}`,
        },
      },
    )
    const data = await response.json()
    if (data.error) {
      throw new Error('queued')
    }

    return data

  } catch (err) {
    // unable to reach clearbit, possibly ad blocker
    window.posthog.capture('adblocker-detected', { email })
    return { person: null, company: null }
  }

}

export const useClearbitUserData = (email: string) => {
  const isValidEmail = Boolean(email) && validate(email)

  const {
    data: rawData,
    isLoading,
    isError,
  } = useQuery(['clearbit-data', email], () => fetchData(email), {
    enabled: isValidEmail,
    retry: 2,
    retryDelay: (failureCount) => failureCount * 800,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: 1000 * 60 * 5,
  })

  // do not change the below structure because backend/pardot expects the data in a very
  // specific shape see https://github.com/richardson-sales-performance/website-serverless
  // for more info (which is the lambda which receives our payload)
  const clearbitPayloadForAwsLambda: ClearbitUserDataType = {
    clearbitCompany: rawData?.company?.name ?? undefined,
    clearbitCompanySize: rawData?.company?.metrics?.employees ?? undefined,
    clearbitEmployeesRange:
      rawData?.company?.metrics?.employeesRange ?? undefined,
    clearbitPhoneNumber: rawData?.company?.phone ?? undefined,
    clearbitCountry: rawData?.company?.geo?.country ?? undefined,
    clearbitSicCode: rawData?.company?.category?.sicCode ?? undefined,
    clearbitAnnualRevenue:
      rawData?.company?.metrics?.annualRevenue ?? undefined,
    clearbitEmailProvider: rawData?.company?.emailProvider ?? undefined,
    clearbitUltimateParentDomain: rawData?.company?.parent?.domain ?? undefined,
    clearbitLinkedinHandle: rawData?.person?.linkedin?.handle ?? undefined,
    clearbitFuzzy: rawData?.person?.fuzzy ?? undefined,
    clearbitIndexedAt: rawData?.company?.indexedAt ?? undefined,
  }

  return {
    clearbitPayloadForAwsLambda,
    isLoading: isLoading && isValidEmail,
    isError,
    rawData,
  }
}
