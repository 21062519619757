import { useEffect, useState } from 'react'
import { isDefined, mapValues, pipe } from 'remeda'
import { identity, pickBy } from 'lodash/fp'
import { validate } from 'email-validator'
import { type FormikConfig, type FormikProps, type FormikValues, useFormik } from 'formik'

import { getGoogleAnalyticsData } from '../../components/GoogleAnalyticsHiddenFields'
import { bingTrackEvent } from '../../hooks/useBingTrackEvent'
import {
  type ClearbitUserDataType,
  useClearbitUserData,
} from '../../hooks/useClearbitUserData'
import { honeybadger } from '../../hooks/useHoneybadger'
import { type EventTrackingName } from '../../hooks/useSplitTracking'
import { pushToDataLayer } from '../dataLayer'
import { cleanString, jsonParseLocalStorage } from '../helpers'
import type {
  Endpoint,
  FormId,
  GoogleAnalyticsFormType,
  LocalStorageFormDataType,
  PardotFormProps,
  PardotFormValues,
} from './types'
import validationSchemaFromIntialValues, { ErrorMessages } from './validate'
import { fingerprintId } from '../id'
import { formSubmissionStore } from '../../components/DownloadResourceForm/store'

export { LocalStorageFormDataType, PardotFormProps, PardotFormValues }
export { fieldError, fieldErrorText } from './field'
export { default as nextDisabled } from './nextDisabled'
export { default as onNext } from './onNext'

export const FORM_LOCALSTORAGE_KEY = 'form_data'

export const defaultInitialPardotFormValues: PardotFormValues = {
  firstName: '',
  lastName: '',
  companyName: '',
  jobTitle: '',
  businessEmail: '',
  country: '',
  numberOfSellers: '',
  phoneNumber: '',
  trainingInterests: '',
  GDPRConsent: false,
}

export const getBusinessEmailFromLocalStorage = () => {
  const localStorageFormData = jsonParseLocalStorage<
    Omit<LocalStorageFormDataType, 'trainingInterests'>
  >(localStorage?.getItem(FORM_LOCALSTORAGE_KEY))

  const localStorageBusinessEmail = localStorageFormData
    ? Object.keys(localStorageFormData)?.[0]
    : ''

  return localStorageBusinessEmail
}

const apiToFieldMap = {
  email: 'businessEmail',
  company: 'companyName',
  sellers: 'numberOfSellers',
  phone: 'phoneNumber',
  region: 'country',
  interests: 'trainingInterests',
  gdprStatus: 'GDPRConsent',
}

const mapPardotFormFieldsToApi = <T extends Record<string, unknown>>({
  GDPRConsent,
  ...values
}: T) => {
  const mappedFields = pipe(
    apiToFieldMap,
    mapValues((value) => values[value]),
    pickBy(identity),
  )
  return {
    ...mappedFields,
    gdprStatus: GDPRConsent && GDPRConsent ? 'Yes' : 'No',
    ...values,
  }
}

const postForm = async <T extends Record<string, unknown>>(
  formId: FormId,
  endpoint: string,
  values: T,
  clearbitUserData: ClearbitUserDataType,
  mapper: (v: T) => Record<string, unknown>,
): Promise<void> => {
  const url = process.env.GATSBY_AWS_ENDPOINT + endpoint

  const googleAnalyticsData = getGoogleAnalyticsData()

  // eslint-disable-next-line no-console
  console.debug({ googleAnalyticsData })

  const currentPageUrl =
    typeof window !== 'undefined' ? window?.location?.href : ''

  const data = {
    ...mapper({
      ...values,
      country: values?.country ? values?.country : 'undefined',
      companyName: values?.companyName ? values?.companyName : 'undefined',
      firstName: values?.firstName ? values?.firstName : 'undefined',
      lastName: values?.lastName ? values?.lastName : 'undefined',
      jobTitle: values?.jobTitle ? values?.jobTitle : 'undefined',
      website: currentPageUrl,
    }),
    ...googleAnalyticsData,
    ...clearbitUserData,
  }

  const phid = googleAnalyticsData.ga_clientid === 'undefined' ? fingerprintId.get() : googleAnalyticsData.ga_clientid
  window.posthog.identify(phid, { form: data })
  console.log(["postForm", { phid, data }])

  const formIdToGoogleAnalyticsFormType: {
    [key in FormId]: GoogleAnalyticsFormType
  } = {
    contactUsForm: 'contact',
    downloadForm: 'resource',
    subscribeForm: 'newsletter',
    multiStepContactForm: 'contact',
    downloadFormEmailOnly: 'resource',
  }
  formSubmissionStore.set(data)

  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  }).then(async (response) => {
    if (response.status >= 400) {
      return Promise.reject(await response.json())
    }

    const body = await response.json()
    const { visitorId } = body

    pushToDataLayer({
      key: 'FORM_SUBMITTED',
      data: {
        event: 'formSuccess',
        visitorId,
        form_type: formIdToGoogleAnalyticsFormType[formId],
        job_title:
          typeof values?.jobTitle === 'string'
            ? values.jobTitle.toLowerCase()
            : null,
        number_of_sellers:
          typeof values?.numberOfSellers === 'number' ||
            typeof values?.numberOfSellers === 'string'
            ? String(values?.numberOfSellers)
            : null,
      },
    })

    // if form is not subscribeForm save data to local storage
    if (
      formId !== 'subscribeForm' &&
      typeof values?.businessEmail === 'string'
    ) {
      const email = values?.businessEmail
      const formData = {}
      formData[email] = values
      if (Object.keys(data)?.length > 0) {
        localStorage.setItem(FORM_LOCALSTORAGE_KEY, JSON.stringify(formData))
      }
    }

    return body
  })
}

export const useForm = <
  T extends FormikValues,
  E extends ErrorMessages = ErrorMessages,
>({
  initialValues,
  formId,
  endpoint,
  onSuccess,
  errors,
  validationSchema = validationSchemaFromIntialValues(
    initialValues,
    errors ?? {},
    formId,
  ),
  mapper = mapPardotFormFieldsToApi,
  emailField,
}: {
  initialValues: T
  formId: FormId
  endpoint: Endpoint
  onSuccess: (values: T) => void
  errors?: E | null
  validationSchema?: (values: T, errs: E) => FormikConfig<T>['validationSchema']
  mapper?: (values: T) => Record<string, unknown>
  emailField: keyof T
}): FormikProps<T> => {
  const [email, setEmail] = useState('')
  const { clearbitPayloadForAwsLambda } = useClearbitUserData(email)

  const track = (eventName: EventTrackingName, data: any) => {
    bingTrackEvent(eventName, data)
    window.gtag && window.gtag('event', eventName, data)
  }

  const formik = useFormik<T>({
    initialValues,
    validationSchema,
    onSubmit: (values, { setFieldError }) => {
      const clearbitInstance = window['clearbit']
      if (typeof clearbitInstance !== 'undefined') {
        honeybadger.setContext({
          email,
        })

        clearbitInstance.identify(email, { email })
      }

      try {
        switch (formId) {
          case 'contactUsForm': {
            track('contact_form__submitted', {
              url: window.location.href,
              ...values,
            })
            break
          }
          case 'multiStepContactForm': {
            track('contact_form_multistep__submitted', {
              url: window.location.href,
              ...values,
            })
            break
          }
          case 'downloadForm': {
            track('download_resource_form__submitted', {
              url: window.location.href,
              ...values,
            })
            break
          }
          case 'downloadFormEmailOnly': {
            track('download_resource_form_email_only__submitted', {
              url: window.location.href,
              ...values,
            })
            break
          }
          default:
          // do nothing
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }

      postForm(
        formId,
        endpoint,
        values,
        clearbitPayloadForAwsLambda,
        mapper,
      )
        .then(() => onSuccess(values))
        .catch((err) => {
          console.error({ err, values })
          formik.setSubmitting(false)
          const path = err?.path
          const field = isDefined(values[path])
            ? err.path
            : apiToFieldMap[path]

          if (field) {
            setFieldError(field, err.message)
          }

          if (err?.success === false) {
            honeybadger.notify(formId, {
              context: {
                payload: values,
              },
            })

            alert(
              'Something went wrong with the form submission. Please try again later.',
            )
          }
        })
    },
  })

  useEffect(() => {
    const emailValue = formik.values[emailField]
    if (validate(`${emailValue}`)) {
      setEmail(emailValue)
    }
  }, [formik.values[emailField]])

  return formik
}

export const useAskGDPRPermission = (
  formContent,
  country: string,
  GDPRConsentFormValue: boolean,
) => {
  const GDPRCountries = formContent?.countries?.[0]?.reference
    ?.filter((region) => region.tags?.includes('gdpr'))
    .map((region) => cleanString(region.copy))

  const isGDPRChecked = GDPRConsentFormValue === true

  if (GDPRCountries && GDPRCountries.includes(country)) {
    if (isGDPRChecked) {
      return false
    }
    return true
  } else if (country === 'undefined' && !isGDPRChecked) {
    return true
  }
  return false
}
