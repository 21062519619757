export const getRegionFromCountryCode = (countryCode: string) => {
  const ca = ['CA']
  const us = ['US']
  const apac = ['TW', 'HK', 'SG', 'ID', 'TH', 'MY', 'PH', 'VN', 'LA', 'KH', 'MM', 'BN', 'KR', 'AU', 'NZ', 'PK', 'BD', 'LK', 'NP', 'BT', 'AU', 'NZ'];
  const emea = ['AL', 'AD', 'AM', 'AZ', 'BE', 'BA', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'GE', 'GR', 'HU', 'IS', 'IE', 'IT', 'LV', 'LT', 'LU', 'MK', 'MT', 'MD', 'ME', 'NL', 'NO', 'PL', 'PT', 'RO', 'SM', 'RS', 'SK', 'SI', 'ES', 'SE', 'CH', 'TR', 'GB', 'BH', 'EG', 'IR', 'IQ', 'IL', 'JO', 'KW', 'LB', 'OM', 'PS', 'QA', 'SA', 'SY', 'AE', 'YE', 'ER', 'ET', 'KE', 'MW', 'MU', 'MZ', 'RW', 'SC', 'SO', 'SS', 'TZ', 'UG', 'ZM', 'ZW', 'AO', 'CM', 'ST', 'LY', 'SD', 'BW', 'LS', 'NA', 'ZA', 'SZ', 'CV', 'GM', 'GH', 'GW', 'LR', 'NG', 'SH', 'SL', 'FR', 'MC', 'BJ', 'BF', 'BI', 'CF', 'TD', 'KM', 'CG', 'CI', 'CD', 'DJ', 'GQ', 'GA', 'GN', 'MG', 'ML', 'NE', 'SN', 'TG', 'YT', 'RE', 'DZ', 'MR', 'MA', 'TN', 'DE', 'AT', 'LI', 'TR', 'CH', 'BE', 'AT', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IS', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'NO', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'UK'];
  const la = ['AR', 'BO', 'BR', 'CL', 'CO', 'EC', 'FK', 'GY', 'PY', 'PE', 'SR', 'UY', 'VE'];

  if (ca.includes(countryCode)) {
    return 'Canada'
  } else if (us.includes(countryCode)) {
    return 'United States'
  } else if (apac.includes(countryCode)) {
    return 'Asia Pasific/Australia/New Zealand'
  } else if (emea.includes(countryCode)) {
    return 'Europe/Middle East/Africa'
  } else if (la.includes(countryCode)) {
    return 'Latin America'
  }
  return false
}
