import { FormikConfig } from 'formik'
import { AnySchema, number, object, string } from 'yup'

import { cleanString } from '../helpers'

export type FormContent = Exclude<
  Exclude<
    Queries.ContactUsPageQuery['contentstackPageContactUs'],
    null
  >['form'],
  null
>[number]

export type ErrorMessages = Partial<
  Pick<
    Exclude<FormContent, null>,
    | 'email_error_message'
    | 'required_error_message'
    | 'numbers_only_error_message'
  >
>

const validationSchemaFromIntialValues = <T extends Record<string, unknown>>(
  initialValues: T,
  errors: ErrorMessages,
  formId: string,
): FormikConfig<T>['validationSchema'] => {
  const emailError = cleanString(errors.email_error_message?.[0]?.copy ?? '')
  const requiredError = cleanString(
    errors.required_error_message?.[0]?.copy ?? '',
  )

  const checkKey = (key: string, schema: AnySchema) =>
    Object.prototype.hasOwnProperty.call(initialValues, key)
      ? { [key]: schema }
      : {}

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/

  const isDownloadResourceForm = formId === 'downloadForm'
  const isContactUsForm = formId === 'contactUsForm'
  const isBrochureDownload =
    isDownloadResourceForm &&
    typeof window !== 'undefined' &&
    window.location.pathname.includes('/download/')

  return object().shape({
    ...checkKey('firstName', string().trim().required(requiredError)),

    ...checkKey('lastName', string().trim().required(requiredError)),

    ...checkKey(
      'businessEmail',
      string()
        .required(requiredError)
        .email(emailError)
        .matches(
          emailRegex,
          'Please enter a correctly formatted email address.',
        ),
    ),

    ...checkKey(
      'email',
      string()
        .required(requiredError)
        .email(emailError)
        .matches(
          emailRegex,
          'Please enter a correctly formatted email address.',
        ),
    ),

    ...((isBrochureDownload || isContactUsForm) &&
      checkKey('companyName', string().trim().required(requiredError))),

    ...checkKey('jobTitle', string().trim().required(requiredError)),

    ...((isBrochureDownload || isContactUsForm) &&
      checkKey('phoneNumber', string().trim().required(requiredError))),

    ...checkKey('country', string().trim().required(requiredError)),

    ...(!isDownloadResourceForm &&
      checkKey('trainingInterests', string().trim().required(requiredError))),

    ...(!isDownloadResourceForm &&
      checkKey(
        'numberOfSellers',
        number()
          .moreThan(0)
          .typeError('You must specify a number')
          .required(requiredError),
      )),
  })
}

export default validationSchemaFromIntialValues
