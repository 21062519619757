import { atom } from 'nanostores'
import { persistentAtom } from '@nanostores/persistent'
export { useStore } from '@nanostores/react'
import { filter, pipe, values } from 'remeda'
import { ClearbitResponse } from '../../hooks/useClearbitUserData'
import { getRegionFromCountryCode } from '../../utils/getRegionFromCountryCode'
import { blankValues, EMPTY, RequiredFormInputs } from './testdata'


// STORED USER is used for saving the basic data in localStorage
// so the user doesnt have to fill the form when returning
// after an original submission
type StoredUser = {
  name?: string
  email?: string
}
export const storedUser = persistentAtom<StoredUser>('stored_user', {}, {
  encode: JSON.stringify,
  decode: JSON.parse,
})
export const clearStoredUser = () => {
  storedUser.set({})
}

export const storedClearbitInitial = { values: blankValues, raw: EMPTY }

export const storedClearbit = atom<{ values: RequiredFormInputs, raw: ClearbitResponse }>(storedClearbitInitial)
// export const storedClearbit = persistentAtom('stored_clearbit', {}, {
//   encode: JSON.stringify,
//   decode: JSON.parse,
// })

export const processClearbit = (raw: ClearbitResponse): [boolean, RequiredFormInputs] => {
  // Check that all the data is there, or make it blank
  const countryRegion = getRegionFromCountryCode(
    raw?.company?.geo?.countryCode || '',
  ) || ''
  const requiredValues: RequiredFormInputs = {
    firstName: raw?.person?.name?.givenName || '',
    lastName: raw?.person?.name?.familyName || '',
    company: raw?.company?.name || '',
    country: countryRegion,
    jobTitle: raw?.person?.employment?.title || '',
  }
  // All data must be non-blank to be valid
  const isBlank = r => r === '' || !Boolean(r)
  const allGood = pipe(requiredValues, values, filter(isBlank)).length === 0
  // After expanding fill the fields, but dont do it again after expanded
  // we don't want it looking creepy
  if (allGood && !$userFormExpanded.get()) {
    storedClearbit.set({ values: requiredValues, raw })
  } else {
    storedClearbit.set({ values: blankValues, raw })
    $userFormExpanded.set(true)
  }

  return [allGood, storedClearbit.get().values]
}

export const $userFormExpanded = atom(false)

export const formSubmissionStore = atom({})