import { useEffect } from 'react'

type DataLayerEvent =
  | {
      key: 'FORM_SUBMITTED'
      data: {
        event: 'formSuccess'
        visitorId: string
        form_type: 'contact' | 'resource' | 'newsletter' | 'contact'
        number_of_sellers: string | null
        job_title: string | null
      }
    }
  | {
      key: 'SOLUTION_PAGE_LANDING'
      data: {
        solutions_group: string
      }
    }
  | {
      key: 'RESOURCE_PAGE_LANDING'
      data: {
        content_type: string
        content_topic: string
      }
    }

export const pushToDataLayer = (event: DataLayerEvent): void => {
  window.dataLayer = window.dataLayer ?? []
  window.dataLayer.push(event.data)

  // eslint-disable-next-line no-console
  console.debug('track', event.key, event.data)
}

const cleanChars = (str: string | undefined | null) =>
  str
    ?.replace(/^[^A-Ba-z0-9]+/gi, '')
    ?.replace(/[^A-Ba-z0-9]+$/gi, '')
    ?.replace(/[^A-Ba-z0-9]+/gi, '_')
    ?.toLowerCase() || undefined

export const useTrackSolutionPage = (
  slug: string,
  data:
    | Queries.productSpecificPageQuery
    | Queries.jobSpecificPageQuery
    | Queries.productOverviewPageQuery,
) => {
  const solutionsGroup = (() => {
    if ('parentUrl' in data && data?.parentUrl?.nodes?.length) {
      return cleanChars(data.parentUrl.nodes[0].pageUrl)
    }

    if (
      'parentCategoryPage' in data &&
      data?.parentCategoryPage?.nodes?.length
    ) {
      return cleanChars(data.parentCategoryPage.nodes[0].url)
    }

    return undefined
  })()

  const fallback = cleanChars(slug)

  const trackValue = solutionsGroup || fallback

  useEffect(() => {
    if (trackValue) {
      pushToDataLayer({
        key: 'SOLUTION_PAGE_LANDING',
        data: {
          solutions_group: trackValue,
        },
      })
    }
  }, [trackValue])

  return undefined
}

export const useTrackResourcePage = (
  data:
    | Queries.downloadableResourcePageQuery
    | Queries.nonDownloadableResourcePageQuery,
) => {
  const contentType = cleanChars(
    data.contentstackPageResource?.resource?.[0]?.resource_type?.[0]?.title,
  )

  const contentTopic =
    cleanChars(
      data.contentstackPageResource?.resource?.[0]?.resource_topic?.[0]?.headline?.toLowerCase(),
    ) || null

  useEffect(() => {
    if (contentType && contentTopic) {
      pushToDataLayer({
        key: 'RESOURCE_PAGE_LANDING',
        data: {
          content_type: contentType,
          content_topic: contentTopic,
        },
      })
    }
  }, [contentTopic, contentType])

  return undefined
}
