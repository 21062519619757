import { createTheme } from '@material-ui/core/styles'

export const rspTheme = {
  navHeight: `100px`,
  navHeightMobile: `60px`,
  outerWrapperWidth: `1800px`,
  mobileNavBreakpoint: `1080px`,
  gradient: 'to bottom, #BD617F, #925B84 60%, #6B4A81',
  palette: {
    text: {
      primary: `#232323`,
      secondary: `#A9938D`,
      disabled: `#9E9E9E`,
      hint: `#9E9E9E`,
    },
    primary: {
      main: `#21154A`,
      btnbackground: `#21154A`,
      dark: `#0D0230`,
      light: `#6B4A81`,
      background: `#F3F1F5`,
      border: `#908AA4`,
      contrastText: `#FFFFFF`,
    },
    secondary: {
      main: `#B07882`,
      dark: `#815059`,
      light: `#C59299`,
      btnbackground: `#A46569`,
      background: `#F9F4F5`,
      // border: `#ECBCC4`,
      border: `#EDE8E8`,
      contrastText: `#FFFFFF`,
    },
    error: {
      main: `#F44336`,
      dark: `#D32F2F`,
      light: `#E57373`,
      contrastText: `#FFFFFF`,
    },
  },
  typography: {
    fontFamily: "'Open Sans', Helvetica, Arial, sans-serif",
    htmlFontSize: 10,
    h1: {
      fontWeight: 300,
      fontSize: `4.8rem`,
      lineHeight: 1.15,
      letterSpacing: `-1.5px`,
    },
    h2: {
      fontWeight: 300,
      fontSize: `3.4rem`,
      lineHeight: 1.25,
      letterSpacing: `-0.5px`,
    },
    h3: {
      fontWeight: 300,
      fontSize: `2.4rem`,
      lineHeight: 1.25,
      letterSpacing: `-0.5px`,
    },
    h4: {
      fontWeight: 300,
      fontSize: `2.2rem`,
      lineHeight: 1.19,
      letterSpacing: `0.25px`,
    },
    h5: {
      fontWeight: 700,
      fontSize: `2.0rem`,
      lineHeight: 1.33,
      letterSpacing: `-0.5px`,
    },
    h6: {
      fontWeight: 300,
      fontSize: `1.8rem`,
      lineHeight: 1.33,
      letterSpacing: `0.15px`,
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: `1.6rem`,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontWeight: 800,
      fontSize: `1.6rem`,
      lineHeight: 1.5,
    },
    body1: {
      fontWeight: 300,
      fontSize: `1.6rem`,
      lineHeight: 1.75,
      letterSpacing: `0.15px`,
    },
    body2: {
      fontWeight: 400,
      fontSize: `1.6rem`,
      lineHeight: 1.43,
    },
    mainNav: {
      fontWeight: 400,
      fontSize: `1.4rem`,
      lineHeight: 1.5,
    },
    buttonLg: {
      fontWeight: 700,
      fontSize: `1.5rem`,
      lineHeight: 1.73,
      textTransform: `uppercase`,
    },
    buttonMd: {
      fontWeight: 700,
      fontSize: `1.4rem`,
      lineHeight: 1.29,
      textTransform: `uppercase`,
    },
    buttonSm: {
      fontWeight: 300,
      fontSize: `1.3rem`,
      lineHeight: 1.69,
      letterSpacing: `0.46px`,
      textTransform: `uppercase`,
    },
    caption: {
      fontWeight: 400,
      fontSize: `1.3rem`,
      lineHeight: 1.5,
      letterSpacing: `-0.2px`,
    },
    overline: {
      fontWeight: 400,
      fontSize: `1.2rem`,
      lineHeight: 2.6,
      letterSpacing: `1px`,
    },
    inputLabel: {
      fontWeight: 400,
      fontSize: `1.2rem`,
      lineHeight: 1.33,
      letterSpacing: `0.15px`,
    },
    helperText: {
      fontWeight: 400,
      fontSize: `1.2rem`,
      lineHeight: 1.67,
      letterSpacing: `0.4px`,
    },
    inputText: {
      fontWeight: 400,
      fontSize: `1.6rem`,
      lineHeight: 1.2,
      letterSpacing: `0.15px`,
    },
    tooltip: {
      fontWeight: 400,
      fontSize: `1.0rem`,
      lineHeight: 1.4,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      smmd: 768,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 8,
}

const theme = createTheme(rspTheme)

export default theme
