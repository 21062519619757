import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'

const Wrapper = styled(Box)({
  width: `90%`,
  maxWidth: `1140px`,
  margin: `0 auto 0`,
})

export default Wrapper
