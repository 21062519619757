import { FormikProps } from 'formik'
import { isEmpty, keys, negate, pick } from 'lodash/fp'
import { isTruthy, pickBy, pipe, reduce } from 'remeda'

const nextDisabled = <
  Values,
  TForm extends FormikProps<Values>,
  T extends Pick<TForm, 'touched' | 'errors'>,
>(
  { touched, errors }: T,
  fields?: readonly (keyof T['touched'])[],
) => {
  const currentFields = fields ?? keys(touched)

  return pipe(
    touched,
    pick(currentFields),
    pickBy(isTruthy),
    keys,
    reduce((touchedErrors, field) => {
      const error = errors[field]
      return error ? [...touchedErrors, error] : touchedErrors
    }, [] as unknown[]),
    negate(isEmpty),
  )
}

export default nextDisabled
