import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

export interface Locale {
  id?: string
  country?: string
  language?: string
  short_code?: string
  region_code?: string
  locale?: string
}

const LocaleContext = createContext<[Locale, Dispatch<SetStateAction<Locale>>]>(
  [{ locale: 'en-us' }, () => {}],
)

const useLocale = () => {
  const [siteLocale, setSiteLocale] = useContext(LocaleContext)

  return { siteLocale, setSiteLocale }
}

interface LocaleProviderProps {
  pageLocale?: string | null
  children: React.ReactNode
}

const LocaleProvider = ({ pageLocale, children }: LocaleProviderProps) => {
  const locale = pageLocale ?? 'en-us'
  const [siteLocale, setSiteLocale] = useState({ locale })

  return (
    <LocaleContext.Provider value={[siteLocale, setSiteLocale]}>
      {children}
    </LocaleContext.Provider>
  )
}

export { LocaleProvider, useLocale }
