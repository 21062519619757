import { FormikProps } from 'formik'
import { mapToObj } from 'remeda'

import nextDisabled from './nextDisabled'

const onNext =
  <Values, T extends FormikProps<Values>>(
    formProps: T,
    fields: readonly (keyof T['touched'])[],
    handleNext: () => void,
  ) =>
  async () => {
    const touched = {
      ...formProps.touched,
      ...mapToObj(fields, (field) => [field, true]),
    }
    await formProps.setTouched(touched)
    const errors = await formProps.validateForm()

    if (!nextDisabled({ ...formProps, touched, errors }, fields)) {
      handleNext()
    }
  }

export default onNext
