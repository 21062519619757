import type { EventTrackingName } from './useSplitTracking'

export const bingTrackEvent = (event: EventTrackingName, payload?: any) => {
  const eventNamesMap: Partial<Record<EventTrackingName, string>> = {
    contact_form__submitted: 'contact',
    contact_form_multistep__submitted: 'contact',
    download_resource_form__submitted: 'resource-form',
    download_resource_form_email_only__submitted: 'resource-form',
  }

  window.uetq = window.uetq || []
  window.uetq.push('event', eventNamesMap[event], payload || {})
}
