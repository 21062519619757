import React from 'react'
import styled from 'styled-components'

import { rspTheme } from '../../../styles/rspTheme'
import Link, { TLink } from '../../Link'

interface LinkCTAProps {
  children: React.ReactNode
  link?: TLink
  directUrl?: string
  size?: 'buttonLg' | 'buttonMd'
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost' | 'focus'
  arrow?: boolean
  state?: unknown
  className?: string
}

interface StyledCtaProps {
  variant: string
  size: string
}

export const colorObject = {
  primary: {
    text: '#ffffff',
    background: rspTheme.palette.primary.main,
    border: `1px solid ${rspTheme.palette.primary.main}`,
    pathFill: rspTheme.palette.primary.background,
    focus: {
      text: '#ffffff',
      background: rspTheme.palette.primary.light,
      border: `1px solid ${rspTheme.palette.primary.light}`,
      pathFill: rspTheme.palette.primary.background,
    },
  },
  secondary: {
    text: '#ffffff',
    background: rspTheme.palette.secondary.dark,
    border: `1px solid ${rspTheme.palette.secondary.dark}`,
    pathFill: rspTheme.palette.secondary.light,
    focus: {
      text: rspTheme.palette.secondary.dark,
      background: rspTheme.palette.secondary.background,
      border: `1px solid ${rspTheme.palette.secondary.light}`,
      pathFill: rspTheme.palette.secondary.light,
    },
  },
  focus: {
    text: '#ffffff',
    background: '#004f71',
    border: `1px solid #004f71`,
    pathFill: '#004f71',
    focus: {
      text: '#004f71',
      background: '#ffffff',
      border: `1px solid #ffffff`,
      pathFill: '#ffffff',
    },
  },
  outline: {
    text: rspTheme.palette.primary.main,
    background: 'transparent',
    border: `1px solid ${rspTheme.palette.primary.main}`,
    pathFill: rspTheme.palette.primary.main,
    focus: {
      text: `#ffffff`,
      background: rspTheme.palette.primary.main,
      border: `1px solid $#ffffff`,
      pathFill: `#ffffff`,
    },
  },
  ghost: {
    text: '#ffffff',
    background: 'transparent',
    border: '1px solid #ffffff',
    pathFill: rspTheme.palette.primary.background,
    focus: {
      text: rspTheme.palette.primary.main,
      background: rspTheme.palette.primary.background,
      border: `1px solid ${rspTheme.palette.primary.main}`,
      pathFill: rspTheme.palette.primary.main,
    },
  },
}

const StyledCta = styled.span<StyledCtaProps>`
  a {
    display: inline-block;
    padding: 8px 22px;
    border-radius: 4px;
    color: ${({ variant }) => colorObject[variant].text};
    background-color: ${({ variant }) => colorObject[variant].background};
    border: ${({ variant }) => colorObject[variant].border};
    transition: all 0.25s ease-in-out;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: ${(props) => `${rspTheme.typography[props.size].fontSize}`};
    display: inline-flex;
    justify-content: center;
    align-items: center;

    path {
      transition: all 0.25s ease-in-out;
      fill: ${({ variant }) => colorObject[variant].pathFill};
    }

    &:hover,
    &:focus {
      color: ${({ variant }) => colorObject[variant].focus.text};
      background-color: ${({ variant }) =>
        colorObject[variant].focus.background};
      border: ${({ variant }) => colorObject[variant].focus.border};

      path {
        fill: ${({ variant }) => colorObject[variant].focus.pathFill};
      }
    }
  }
`

const Arrow = () => (
  <div style={{ marginLeft: '10px', opacity: '0.54' }}>
    <svg
      fill="none"
      height="9"
      viewBox="0 0 5 9"
      width="5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 9L5 4.5L0 0V9Z" fill="#FFFFFF" />
    </svg>
  </div>
)

const LinkCTA = (props: LinkCTAProps) => {
  const {
    children,
    link,
    variant = 'primary',
    size = 'buttonLg',
    arrow = false,
    directUrl,
    state,
    className,
  } = props

  return (
    <StyledCta className={className} size={size} variant={variant}>
      <Link directUrl={directUrl} link={link} state={state}>
        <>
          {children}
          {arrow ? <Arrow /> : null}
        </>
      </Link>
    </StyledCta>
  )
}

export default LinkCTA
