import { Honeybadger, HoneybadgerErrorBoundary } from '@honeybadger-io/react'
import React, { FC, useEffect } from 'react'

import { getBusinessEmailFromLocalStorage } from '../utils/form/useForm'
import { useServerSideSafeValue } from '../utils/helpers'

export const honeybadger = Honeybadger.configure({
  apiKey: process.env.GATSBY_HONEYBADGER_API_TOKEN,
  environment:
    process.env.NODE_ENV === 'development'
      ? 'local'
      : process.env.GATSBY_CONTENTSTACK_ENVIRONMENT,
})

export const HoneybadgerErrorBoundaryProvider: FC = ({ children }) => {
  const { value: localStorageBusinessEmail, isReady } = useServerSideSafeValue(
    () => getBusinessEmailFromLocalStorage(),
  )

  useEffect(() => {
    if (isReady && localStorageBusinessEmail) {
      honeybadger.setContext({
        email: localStorageBusinessEmail,
      })
    }
  }, [isReady, localStorageBusinessEmail])

  return (
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      {children}
    </HoneybadgerErrorBoundary>
  )
}
